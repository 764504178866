<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">{{ $t('side_menu_chart_accounts') }}</p>
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-sheet class="d-flex align-center px-4" height="80" outlined>
          <v-sheet width="300">
            <v-text-field
              v-model="searchText"
              outlined
              dense
              solo
              flat
              :label="$t('search_accounts')"                
              hide-details
            />
        </v-sheet>
        <v-btn
          color="primary"
          class="ml-2 text-capitalize"
          height="40"
          @click="onToggleChartAccountForm"
        >
          {{ $t('add_account') }}
        </v-btn>

        </v-sheet>
        <v-sheet
          v-for="group in tableGroup"
          :key="group"
          class="mt-6 elevation-1"
          outlined
        >
          <v-data-table
            :headers="tableHeaders"
            :loading="isFetchingData"
            :items="chartAccountsData[group]"
            :search="searchText"
          >
            <template v-slot:top>
              <v-toolbar flat outlined>
                <v-toolbar-title class="text-capitalize">
                  {{ group }}
                </v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item="{item}" v-if="!$vuetify.breakpoint.mobile">
              <tr :key="`acct-${item.id}`">
                <td>
                  <a href="#" v-if="Array.isArray(item.sub_accounts) && item.sub_accounts.length" @click.prevent="item.showSubAccount = !item.showSubAccount">
                    {{ item.code }}
                    <v-icon>mdi-{{ !item.showSubAccount ? 'chevron-right' : 'chevron-down' }}</v-icon>
                  </a>
                  <span v-else>{{ item.code }}</span>
                </td>
                <td>{{ item.trans_name }}</td>
                <td>{{ item.account_type }}</td>
                <td>
                  <span :class="{'red--text': item.balance < 0, 'blue--text': item.balance > 0}">{{ currencyFormat(item.balance) }}</span>
                </td>
                <td>
                  <v-icon @click="onSelectToEdit(item)">mdi-pencil</v-icon>
                  <v-icon @click="onSelectToDelete(item)">mdi-delete</v-icon>
                </td>
              </tr>
              <tr v-if="item.showSubAccount" v-for="sub_account in item.sub_accounts" :key="`sub-${sub_account.id}`">
                <td class="sub-account-code"><v-icon>mdi-subdirectory-arrow-right</v-icon> {{ sub_account.code }}</td>
                <td colspan="2">{{ sub_account.trans_name }}</td>
                <td></td>
                <td>
                  <v-icon @click="onSelectToEdit(sub_account)">mdi-pencil</v-icon>
                  <v-icon @click="onSelectToDelete(sub_account)">mdi-delete</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:item.balance="{item}">
              <span :class="{'red--text': item.balance < 0, 'blue--text': item.balance > 0}">{{ currencyFormat(item.balance) }}</span>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click="onSelectToEdit(item)">mdi-pencil</v-icon>
              <v-icon @click="onSelectToDelete(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-sheet>
        <chart-form
          :open="showChartAccountForm"
          @toggle="onToggleChartAccountForm"
          @saved="chartAccountSaved"
          :form-values="selectedDataToEdit"
          :is-edit-mode="isEditMode"
        />
        <chart-delete :open="showChartAccountDelete" :form-data="selectedDataToDelete" @toggle="onToggleDelete" />
        <v-snackbar
          timeout="2000"
          vertical
          :color="snackbarOption.color"
          v-model="showSnackbar"
          bottom
        >
          <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
        </v-snackbar>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChartForm from '../components/ChartAccountsForm.vue'
import ChartDelete from '../components/ChartAccountDeleteModal.vue'
import { apiErrorMessage, debounce } from '@/utils/general'
import Pagination from '../components/Pagination.vue';
export default {
  name: 'ChartAccounts',
  components: {
    ChartForm,
    Pagination,
    ChartDelete,
  },
  data() {
    return {
      isFetchingData: false,
      chartAccountsData: [],
      showChartAccountForm: false,
      showChartAccountDelete: false,
      isFetchingAccountTypes: false,
      searchText: '',
      accountTypes: {},
      tableGroup: [
        'assets',
        'liabilities',
        'expenses',
        'income',
        'equity',
      ],
      selectedDataToDelete: null,
      selectedDataToEdit: null,
      snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
      showSnackbar: false,
      isEditMode: false,
    }
  },

  mounted() {
    this.fetchChartAccounts();
  },

  computed: {
    ...mapGetters('accounting', ['isQBOEnabled', 'homeCurrency', 'getDEtypes']),
    tableHeaders() {
      return [
        {
          text: this.$t('code'),
          value: 'code',
          class: 'text-uppercase th--text font-weight-bold',
          width: '15%'
        },
        {
          text: this.$t('name'),
          value: 'trans_name',
          class: 'text-uppercase th--text font-weight-bold'
        },
        {
          text: this.$t('type'),
          value: 'account_type',
          class: 'text-uppercase th--text font-weight-bold'
        },
        {
          text: this.$t('balance'),
          value: 'balance',
          class: 'text-uppercase th--text font-weight-bold'
        },
        {
          value: 'actions',
          width: 80
        }
      ];
    },
  },

  /* watch: {
    searchText: debounce(function() {
      this.fetchChartAccounts();
    }, 300)
  }, */

  methods: {
    ...mapActions('accounting', ['getChartAccounts']),

    currencyFormat(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.homeCurrency }).format(value);
    },

    onToggleChartAccountForm() {
      this.showChartAccountForm = !this.showChartAccountForm;
      this.isEditMode = false;
      this.selectedDataToEdit = null;
    },

    async fetchChartAccounts() {
      if(this.isFetchingData) {
        return;
      }
      this.isFetchingData = true;
      try {
        const { data } = await this.getChartAccounts({
          search: this.searchText || '',
        });

        const chartData = {};
        data.forEach(record => {
          const accountType = record.declass.name.split('.').pop();
          if(typeof chartData[accountType] === 'undefined') {
            chartData[accountType] = [];
          }
          chartData[accountType].push({
            ...record,
            account_type: this.getDEtypes[record.type.name] || '',
            showSubAccount: false,
            balance: record.debit_total - record.credit_total,
          })
        });

        this.chartAccountsData = chartData;
        this.isFetchingData = false;
      } catch(error) {
        apiErrorMessage(error)
        this.isFetchingData = false;
      }
    },

    onSelectToEdit(data) {
      this.selectedDataToEdit = Object.assign({}, data);
      this.showChartAccountForm = true;
      this.isEditMode = true;
    },

    onToggleEdit() {
    },

    onSelectToDelete(data) {
      this.selectedDataToDelete = Object.assign({}, data);
      this.showChartAccountDelete = true;
    },

    onToggleDelete(options) {
      this.showChartAccountDelete = false;
      if(options && options.deleted) {
        this.selectedDataToDelete = null;
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'red',
					message: options.message || 'Deleted.',
				};
        this.showSnackbar = true;
        this.fetchChartAccounts();
      }
    },

    chartAccountSaved(options) {
      this.showChartAccountForm = false;
      this.snackbarOption = {
        icon: 'mdi-check',
        color: 'success',
        message: options.message || 'Saved.',
      };
      this.showSnackbar = true;
      this.fetchChartAccounts();
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-app {
    background: none;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table {
    thead {
      th {
        vertical-align: middle !important;
      }
    }

    tbody {
      tr td {
        vertical-align: middle !important;
        border-bottom: 1px solid #EBF2F5;
        padding: 14px 15px !important;
      }
      tr.v-data-table__selected {
        background-color: #f0fbff !important;
      }
    }
  }
  .sub-account-code {
    text-indent: 32px;
  }
</style>